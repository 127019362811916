<template>
  <div>
    <div class="modal-body">
      <payment-type :variant="payment.status" />
      <div class="text-tight mb-3">
        <label class="mr-2">Status:</label>
        {{ payment.status }}
      </div>
      <div class="text-tight mb-3">
        <label class="mr-2">Method:</label>
        {{ payment.method_title }}
      </div>
      <div class="text-tight mb-3">
        <label class="mr-2">Total:</label>
        <money-format
          class="inline"
          :value="payment.total / 100"
          :locale="'en'"
          :currency-code="order.currency"
        />
      </div>
      <div v-if="payment.transaction" class="text-tight mb-3">
        <label class="mr-2">Transaction:</label>
        {{ payment.transaction }}
      </div>
      <h6>Meta fields</h6>
      <!-- eslint-disable vue/no-use-v-if-with-v-for,vue/no-confusing-v-for-v-if -->
      <div
        v-for="meta in payment.meta"
        :key="meta.key"
        v-if="isFieldActive('payment', meta.key)"
        class="text-tight mb-3"
      >
        <label class="åmr-2"> {{ fieldLabel("payment", meta.key) }}: </label>
        {{ meta.value }}
      </div>
    </div>
  </div>
</template>

<script>
import PaymentType from "@/components/Order/PaymentType.vue";
import MoneyFormat from "vue-money-format";

export default {
  name: "PaymentDetails",
  components: {
    PaymentType,
    MoneyFormat,
  },
  props: {
    payment: {
      type: Object,
      required: true,
      default: () => {
        return {};
      },
    },
    order: {
      type: Object,
      required: true,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      meta: {},
    };
  },
  methods: {
    close() {
      this.$parent.$emit("close");
    },
    isFieldActive(type, field) {
      if (this.meta && this.meta[type]) {
        return Object.keys(this.meta[type]).includes(field);
      }
      return false;
    },
    fieldLabel(type, field) {
      return this.meta[type][field];
    },
  },
};
</script>

<style lang="scss" scoped>
.inline {
  display: inline-block;
}
.text-tight {
  line-height: 0.8rem;
}
</style>
