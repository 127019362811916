<template>
  <ul
    v-if="displays.length"
    class="list-group list-group-horizontal d-flex justify-content-end"
  >
    <li
      v-for="display in displays"
      :key="display.id"
      class="list-group-item p-2"
    >
      <router-link
        :to="{
          name: 'order.display',
          params: { id: orderId, did: display.id },
        }"
      >
        {{ display.data.name }}
      </router-link>
    </li>
  </ul>
</template>

<script>
import jsonAPI from "@/api";
export default {
  name: "OrderDisplayList",
  props: {
    orderId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      displays: [],
    };
  },
  mounted() {
    let uri = "/configuration/display?page=1";

    if (this.$route.query.sort && this.$route.query.dir) {
      uri +=
        "&sort=" + this.$route.query.sort + "&dir=" + this.$route.query.dir;
    }

    jsonAPI
      .get(uri)
      .then((response) => {
        this.displays = response.data.data;
      })
      .catch(() => {
        window.Bus.$emit("flash-message", {
          text: "Error fetching results",
          type: "error",
        });
      });
  },
};
</script>

<style scoped></style>
