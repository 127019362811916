<template>
  <component :is="layout">
    <prompt
      :active="viewPaymentDetails"
      :title="'Payment Details'"
      @close="viewPaymentDetails = false"
    >
      <div slot="content">
        <PaymentDetails
          v-if="viewPaymentDetails"
          :payment="selectedPayment"
          :order="order"
        />
      </div>
    </prompt>
    <div class="row fadeInUp" role="main">
      <div class="col-12 mb-4">
        <OrderDisplayList :order-id="order.id" />
      </div>
      <div class="col-md-8">
        <div v-if="!showJson" class="row">
          <div class="col-md-12">
            <div class="card border-0 shadow-sm mb-4">
              <div class="card-body">
                <h5>
                  <span class="font-weight-bold"
                    >Order #{{ order.order_number }}</span
                  >
                  <status-badge :variant="order.status" class="float-right">
                    {{ order.status }}
                  </status-badge>
                </h5>
                <p class="text-muted mb-0">
                  {{ order.created_at | formatLocaleDateString }}
                </p>
              </div>
              <div class="col-lg-12 pb-2">
                <h5 class="text-muted pt-3 pb-3 mb-4 border-bottom">
                  Meta fields
                  <router-link
                    :to="{ name: 'meta.fields.index' }"
                    class="btn btn-outline-primary btn-sm float-right"
                    >Add fields</router-link
                  >
                </h5>
                <div
                  :key="meta.key"
                  v-for="meta in getActiveMetaFields"
                  class="meta-column text-tight mb-3"
                >
                  <label class="mr-2"
                    >{{ fieldLabel("order", meta.key) }}:</label
                  >
                  {{ meta.value }}
                </div>
              </div>
            </div>
            <div class="card border-0 shadow-sm mb-4">
              <div
                v-if="order.customer"
                class="card-header d-flex flex-column flex-md-row justify-content-between align-items-lg-center align-items-md-start"
              >
                <p class="px-0 py-1 m-1">
                  Customer
                </p>
                <div class="px-0 py-1 m-0">
                  <router-link
                    :to="{
                      name: 'contact.single',
                      params: { id: customerId },
                    }"
                    class="btn btn-sm btn-outline-secondary"
                  >
                    View customer
                  </router-link>
                </div>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-lg-4">
                    <address-display
                      :title="'Billing address'"
                      :address="order.billing_address"
                    />
                  </div>
                  <div class="col-lg-4">
                    <address-display
                      :customer_id="customerId"
                      :title="'Delivery address'"
                      :address="order.shipping_address"
                    />
                  </div>
                  <div class="col-lg-4">
                    <h5 class="mb-3">
                      Payment
                    </h5>
                    <div class="media">
                      <div class="payment">
                        <div class="d-flex justify-content-between font-weight-bold mb-2">
                          <div class="pr-2 method">Method</div>
                          <div class="pr-2">Status</div>
                          <div class="pr-2">Action</div>
                        </div>
                        <div
                          class="d-flex justify-content-between mb-1"
                          v-for="payment in order.payments" :key="payment.id"
                        >
                           <div class="pr-2 method">
                            {{ payment.method_title }}
                          </div>
                          <div class="pr-2">
                            {{ payment.status }}
                          </div>
                          <div class="pr-2">
                            <button
                              class="btn btn-sm btn-outline-secondary"
                              @click="showPaymentDetails(payment)"
                            >
                              view
                            </button>
                          </div>
                        </div>
                      </div>
                      <!-- eslint-disable vue/no-use-v-if-with-v-for,vue/no-confusing-v-for-v-if -->
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-body border-top">
                <Tag v-for="tag in order.tags" :key="tag" :slug="tag" />
                <button
                  class="badge badge-pill d-inline border rounded-pill m-1 px-2 py-1 btn btn-primary btn-sm"
                  @click="openAddTag"
                  v-if="hasPermission('create-tag')"
                >
                  <small-circle-plus :height="'12'" />
                  Add or Delete tags
                </button>
                <prompt
                  :active="isAddTagVisible"
                  @close="isAddTagVisible = false"
                >
                  <h3 slot="header">
                    Add Tags
                  </h3>
                  <div slot="content">
                    <add-tag
                      v-if="order.id"
                      :tagmodel="order"
                      :url="'order'"
                      @updateTagModelObject="updateObject"
                    />
                  </div>
                </prompt>
              </div>
              <div class="card-body border-top">
                <Collapse :title="'Shipment Summary'">
                  <div class="table-responsive">
                    <table class="table table-striped border-bottom">
                      <thead class="bg-200 text-900">
                        <tr>
                          <th class="border-0">
                            Status
                          </th>
                          <th class="border-0 text-center">
                            Carrier
                          </th>
                          <th class="border-0 text-right">
                            Quantity
                          </th>
                          <th class="border-0 text-right">
                            Weight
                          </th>
                          <th class="border-0 text-right">
                            Trackingnumber
                          </th>
                          <th class="border-0 text-right">
                            Price
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="shipment in order.shipments"
                          :key="shipment.id"
                        >
                          <td class="align-middle">
                            {{ shipment.status }}
                          </td>
                          <td class="align-middle text-center">
                            {{ shipment.carrier_title }}
                          </td>
                          <td class="align-middle text-right">
                            {{ shipment.total_qty }}
                          </td>
                          <td class="align-middle text-right">
                            {{ shipment.total_weight }}
                          </td>
                          <td class="align-middle text-right">
                            {{ shipment.track_number || "-" }}
                          </td>
                          <td class="align-middle text-right">
                            <money-format
                              class="inline"
                              :value="shipment.total / 100"
                              :locale="'en'"
                              :currency-code="order.currency"
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </Collapse>
              </div>
            </div>
            <div class="card border-0 shadow-sm mb-4">
              <div class="card-header">
                <p class="mb-0">
                  Summary
                  <span class="float-right"
                    >Total:
                    <money-format
                      class="inline"
                      :value="order.total / 100"
                      :locale="'en'"
                      :currency-code="order.currency"
                  /></span>
                </p>
              </div>
              <div class="card-body">
                <div class="table-responsive fs--1">
                  <table class="table table-striped border-bottom">
                    <thead class="bg-primary text-white">
                      <tr>
                        <th class="border-0">
                          Products
                        </th>
                        <th class="border-0 text-center">
                          Quantity
                        </th>
                        <th class="border-0 text-right">
                          Rate
                        </th>
                        <th class="border-0 text-right">
                          Amount
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="item in order.order_items" :key="item.id">
                        <td class="align-middle">
                          <Collapse class="border-bottom-0" :title="item.name">
                            <div
                              v-if="item.meta"
                              v-for="imeta in item.meta" :key="imeta.key"
                            >
                              <span
                                class="text-muted"
                                v-if="imeta.type === 'string'"
                              >
                                <span>
                                  {{ fieldLabel("orderItem", imeta.key) ? fieldLabel("orderItem", imeta.key) : imeta.key }}:
                                </span>
                                {{ imeta.value }}
                              </span>
                            </div>
                          </Collapse>
                        </td>
                        <td class="align-middle text-center">
                          {{ item.qty_ordered }}
                        </td>
                        <td class="align-middle text-right">
                          <money-format
                            class="inline"
                            :value="item.price / 100"
                            :locale="'en'"
                            :currency-code="order.currency"
                          />
                        </td>
                        <td class="align-middle text-right">
                          <money-format
                            class="inline"
                            :value="item.total / 100"
                            :locale="'en'"
                            :currency-code="order.currency"
                          />
                        </td>
                      </tr>
                      <tr v-for="item in order.shipments" :key="item.id">
                        <td class="align-middle">
                          <p class="mb-0">
                            Shipping
                          </p>
                        </td>
                        <td class="align-middle text-center">
                          {{ item.total_qty }}
                        </td>
                        <td class="align-middle text-right">
                          -
                        </td>
                        <td class="align-middle text-right">
                          <money-format
                            class="inline"
                            :value="item.total / 100"
                            :locale="'en'"
                            :currency-code="order.currency"
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="row no-gutters justify-content-end">
                  <div class="col-auto">
                    <table class="table table-sm table-borderless text-right">
                      <tbody>
                        <tr>
                          <th class="text-900">
                            Subtotal:
                          </th>
                          <td class="font-weight-semi-bold">
                            <money-format
                              class="inline"
                              :value="(order.total - order.total_tax) / 100"
                              :locale="'en'"
                              :currency-code="order.currency"
                            />
                          </td>
                        </tr>
                        <tr>
                          <th class="text-900">
                            Tax:
                          </th>
                          <td class="font-weight-semi-bold">
                            <money-format
                              class="inline"
                              :value="order.total_tax / 100"
                              :locale="'en'"
                              :currency-code="order.currency"
                            />
                          </td>
                        </tr>
                        <tr class="border-top">
                          <th class="text-900">
                            Total:
                          </th>
                          <td class="font-weight-semi-bold">
                            <money-format
                              class="inline"
                              :value="order.total / 100"
                              :locale="'en'"
                              :currency-code="order.currency"
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="card border-0 shadow-sm mb-4"
              v-if="hasPermission('delete-orders')"
            >
              <div class="card-header">
                <DeleteButton
                  :uri="`order/${order.id}`"
                  :name="`order ${order.id}`"
                  @deleted="orderDeleted"
                />
              </div>
            </div>
          </div>
        </div>
        <div v-if="showJson" class="row">
          <div class="col-md-12">
            <div class="card border-0 shadow-sm mb-4">
              <div class="card-body">
                <pre>
                    <code>
                      {{ orderAsJason }}
                    </code>
                  </pre>
                  <h3>Automation log:</h3>
                  <pre>
                    <code>
                      {{ logAsList }}
                    </code>
                  </pre>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="custom-control custom-switch mb-3">
          <input
            id="couponSwitch"
            v-model="showJson"
            type="checkbox"
            class="custom-control-input"
          />
          <label class="custom-control-label" for="couponSwitch"
            >Show json data</label
          >
        </div>
        <div class="card border-0 shadow-sm mb-4">
          <div class="card-body">
            <div class="form-group">
              <label for="status" class="control-label text-capitalize"
                >Change status</label
              >
              <multiselect
                id="status"
                v-model="currentStatus"
                name="status"
                :options="getStatusList"
                track-by="key"
                label="name"
                :allow-empty="false"
                :show-labels="false"
                @select="changeStatus"
              />
            </div>
            <div class="form-group">
              <label for="user" class="control-label text-capitalize"
                >Change user</label
              >
              <multiselect
                id="user"
                v-model="currentUser"
                name="user"
                :options="users"
                track-by="id"
                label="name"
                :allow-empty="false"
                :show-labels="false"
                @select="changeUser"
              />
            </div>
            <div class="form-group">
              <label for="store" class="control-label text-capitalize"
                >Change store</label
              >
              <multiselect
                id="store"
                v-model="currentStore"
                name="store"
                :options="stores"
                track-by="id"
                label="name"
                :allow-empty="false"
                :show-labels="false"
                @select="changeStore"
              />
            </div>
            <div v-if="order.system.links.edit">
              <hr />
              <a
                :href="order.system.links.edit"
                target="_blank"
                class="btn btn-block btn-outline-primary"
                >Edit order on {{ order.system.domain }}</a
              >
            </div>
          </div>
        </div>
        <div class="card border-0 shadow-sm mb-4">
          <div class="card-header border-bottom">
            <p class="mb-0">
              Order note
            </p>
          </div>
          <div class="card-body border-bottom">
            <div v-if="notesLoading" class="c-loader">
              <div class="c-loader__content">
                <div class="c-loader__spinner" />
              </div>
            </div>
            <div v-else>
              <div v-if="order.customer_note" class="mb-3">
                <div class="well well-sm mb-2">
                  <nl2br
                    tag="p"
                    class-name="mb-0"
                    :text="order.customer_note"
                  />
                </div>
                <div class="meta w-100">
                  <span class="author"
                    ><span class="mr-1"
                      ><svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="16"
                        height="16"
                      >
                        <path
                          class="heroicon-ui"
                          d="M9 12A5 5 0 119 2a5 5 0 010 10zm0-2a3 3 0 100-6 3 3 0 000 6zm8 11a1 1 0 01-2 0v-2a3 3 0 00-3-3H7a3 3 0 00-3 3v2a1 1 0 01-2 0v-2a5 5 0 015-5h5a5 5 0 015 5v2zm-1.3-10.7l1.3 1.29 3.3-3.3a1 1 0 011.4 1.42l-4 4a1 1 0 01-1.4 0l-2-2a1 1 0 011.4-1.42z"
                          fill="#6900ff"
                        /></svg
                    ></span>
                    Note from customer</span
                  >
                  <span class="date float-right">{{
                    order.created_at | formatDateHours
                  }}</span>
                </div>
              </div>
              <div v-for="note in orderNotes.data" :key="note.id" class="mb-3">
                <div class="well well-sm mb-2">
                  <nl2br tag="p" class-name="mb-0" :text="note.note" />
                </div>
                <div class="meta w-100">
                  <span class="author"
                    ><span v-if="note.customer_note" class="mr-1"
                      ><svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="16"
                        height="16"
                      >
                        <path
                          class="heroicon-ui"
                          d="M9 12A5 5 0 119 2a5 5 0 010 10zm0-2a3 3 0 100-6 3 3 0 000 6zm8 11a1 1 0 01-2 0v-2a3 3 0 00-3-3H7a3 3 0 00-3 3v2a1 1 0 01-2 0v-2a5 5 0 015-5h5a5 5 0 015 5v2zm-1.3-10.7l1.3 1.29 3.3-3.3a1 1 0 011.4 1.42l-4 4a1 1 0 01-1.4 0l-2-2a1 1 0 011.4-1.42z"
                          fill="#6900ff"
                        />
                      </svg>
                    </span>
                    {{ note.author }}</span
                  >
                  <span class="date float-right">{{ note.date_created }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="card-header border-bottom">
            <p class="mb-0">
              Comments
            </p>
          </div>
          <div class="card-body border-bottom">
            <div
              v-for="comment in order.comments"
              v-if="order.comments"
              :key="comment.id"
            >
              <div class="mb-3">
                <div class="well well-sm mb-2">
                  <nl2br tag="p" class-name="mb-0" :text="comment.comment" />
                </div>
                <div class="meta w-100">
                  <span class="author"
                    ><span class="mr-1"
                      ><svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="16"
                        height="16"
                      >
                        <path
                          class="heroicon-ui"
                          d="M9 12A5 5 0 119 2a5 5 0 010 10zm0-2a3 3 0 100-6 3 3 0 000 6zm8 11a1 1 0 01-2 0v-2a3 3 0 00-3-3H7a3 3 0 00-3 3v2a1 1 0 01-2 0v-2a5 5 0 015-5h5a5 5 0 015 5v2zm-1.3-10.7l1.3 1.29 3.3-3.3a1 1 0 011.4 1.42l-4 4a1 1 0 01-1.4 0l-2-2a1 1 0 011.4-1.42z"
                          fill="#6900ff"
                        /></svg
                    ></span>
                    Note from customer</span
                  >
                  <span class="date float-right">{{
                    comment.created_at | formatDateHours
                  }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="form-group">
              <textarea
                id="exampleFormControlTextarea1"
                v-model="orderNote.note"
                class="form-control"
                rows="3"
              />
            </div>
            <div class="form-row">
              <div class="col">
                <div class="custom-control custom-switch">
                  <input
                    id="typeSwitch"
                    v-model="orderNote.customer_note"
                    type="checkbox"
                    class="custom-control-input"
                  />
                  <label class="custom-control-label" for="typeSwitch"
                    >Send to customer</label
                  >
                </div>
              </div>
              <div class="col">
                <ButtonLoading
                  :loading="notesLoading"
                  :title="'Add note'"
                  :class="'btn btn-primary float-right'"
                  v-on:click.native="addNote"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </component>
</template>

<script>
import { mapGetters } from "vuex";
import MoneyFormat from "vue-money-format";
import Multiselect from "vue-multiselect";
import jsonAPI from "@/api";
import { updateApi } from "@/helpers/apiConnection";
import Default from "@/layouts/default.vue";
import StatusBadge from "@/components/UI/StatusBadge.vue";
import PaymentType from "@/components/Order/PaymentType.vue";
import AddressDisplay from "@/components/UI/AddressDisplay.vue";
import Tag from "@/components/UI/Tag.vue";
import SmallCirclePlus from "@/components/Svg/SmallCirclePlus.vue";
import Prompt from "@/components/UI/Prompt.vue";
import AddTag from "@/components/Form/AddTag.vue";
import Nl2br from "@/helpers/Nl2br";
import OrderDisplayList from "@/components/Order/OrderDisplayList.vue";
import Permission from "@/utils/permission";
import DeleteButton from "@/components/Form/DeleteButton.vue";
import PaymentDetails from "@/components/Order/PaymentDetails.vue";
import ButtonLoading from "@/components/Form/ButtonLoading.vue";
import Collapse from "@/components/UI/Collapse.vue";

export default {
  name: "OrderView",
  extends: Permission,
  components: {
    Default,
    Multiselect,
    StatusBadge,
    PaymentType,
    AddressDisplay,
    Tag,
    SmallCirclePlus,
    Prompt,
    AddTag,
    Nl2br,
    MoneyFormat,
    OrderDisplayList,
    DeleteButton,
    PaymentDetails,
    ButtonLoading,
    Collapse,
},
  beforeRouteEnter(to, from, next) {
    const _id = to.params.id;
    jsonAPI.get("order/" + _id).then((response) => {
      next((vm) => {
        vm.order = response.data;
      });
    });
  },
  data() {
    return {
      layout: "Default",
      order: {
        id: null,
        customer: {},
        system: {
          links: {},
        },
      },
      showShipping: false,
      component: null,
      data: null,
      notesLoading: false,
      orderNote: {
        customer_note: false,
        note: null,
        added_by_user: true,
      },
      orderNotes: {
        data: [],
        loading: true,
      },
      meta: {},
      showJson: false,
      isAddTagVisible: false,
      currentStatus: "",
      currentUser: "",
      currentStore: "",
      stores: [],
      users: [],
      orderId: null,
      viewPaymentDetails: false,
      selectedPayment: "",
      orderLogs: [],
    };
  },
  computed: {
    ...mapGetters(["getStatusList", "getOrganization"]),
    desc() {
      if (this.form.trigger.id !== 0 && this.form.action.id !== 0)
        return (
          "When " + this.form.trigger.text + " then " + this.form.action.text
        );
      else return "Standard desc";
    },
    hasCustomMeta() {
      return Object.keys(this.meta).length !== 0;
    },
    orderAsJason() {
      return this.order;
    },
    logAsList() {
      const logs = this.orderLogs.map((log) => {
        return `${log.event} : ${log.action} - ${log.createdAt}`;
      })
      return logs.join("\n");
    },
    customerId() {
      if (this.order.customer && this.order.customer.id) {
        return this.order.customer.id;
      }
      return "";
    },
    getActiveMetaFields() {
      const keys = Object.keys(this.order.meta);
      const fields = Object.assign({}, this.order.meta);
      keys.forEach((key) => {
        if (!this.isFieldActive("order", key)) {
          delete fields[key];
        }
      });
      return fields;
    },
  },
  async mounted() {
    this.getCustomMeta();
    if (this.getStatusList.length == 0) {
      await this.$store.dispatch("GET_STATUS_LIST");
    }

    this.currentStatus = this.getStatusList.filter((obj) => {
      return obj.key === this.order.status;
    })[0];
    this.currentUser = this.getOrganization.users.filter((obj) => {
      return obj.id === this.order.user_id;
    })[0];
    this.users = this.getOrganization.users;
    if (this.currentUser) {
      this.users.push({ id: "", name: "Unassign" });
    }

    await jsonAPI.get("store").then((response) => {
      this.stores = response.data.data;
      this.currentStore = response.data.data.filter((obj) => {
        return obj.id === this.order.store_id;
      })[0];

      if (this.currentStore) {
        this.stores.push({ id: "", name: "Unassign" });
      }
    });

    await jsonAPI.get(`automation/log/${this.order.id}/Order?per_page=30`).then((response) => {
      this.orderLogs = response.data.data;
    });
  },
  methods: {
    getCustomMeta() {
      jsonAPI.get("/order/dynamicFieldCollection/active").then((response) => {
        this.meta = response.data;
      });
    },
    addNote() {
      let comment = {
        order_id: this.order.id,
        comment: this.orderNote.note,
      };
      if (this.orderNote.customer_note) {
        comment.send_to_customer = true;
      }
      jsonAPI
        .post("/order/comment", comment)
        .then((response) => {
          window.Bus.$emit("flash-message", {
            text: "Comment saved",
            type: "success",
          });
          this.order.comments.push(response.data);
          this.orderNote.note = "";
          this.orderNote.customer_note = false;
        })
        .catch(() => {
          window.Bus.$emit("flash-message", {
            text: "Could not save comment",
            type: "error",
          });
          this.loading = false;
        });
    },
    async changeStatus(newStatus) {
      if (newStatus.key !== this.order.status) {
        try {
          await updateApi(
            `/order/change-status/${this.order.id}`,
            {
              status: newStatus.key,
            },
            "Order status changed"
          );
        } catch (e) {
          console.log(e);
        }
        this.loading = false;
      }
    },
    async changeUser(newUser) {
      if (newUser.key !== this.order.user_id) {
        try {
          await updateApi(
            `/order/change-user/${this.order.id}`,
            {
              user_id: newUser.id,
            },
            "User assigned to order"
          );
        } catch (e) {
          console.log(e);
        }
        this.loading = false;
      }
    },
    async changeStore(newStore) {
      if (newStore.key !== this.order.store_id) {
        try {
          await updateApi(
            `/order/change-store/${this.order.id}`,
            {
              store_id: newStore.id,
            },
            "Order assigned to store"
          );
        } catch (e) {
          console.log(e);
        }
        this.loading = false;
      }
    },
    isFieldActive(type, field) {
      if (this.meta && this.meta[type]) {
        return Object.keys(this.meta[type]).includes(field);
      }
      return false;
    },
    fieldLabel(type, field) {
      if (this.meta[type]) {
        return this.meta[type][field];
      }
      return "";
    },
    hasMeta(items) {
      let metaExists = false;
      items.forEach((item) => {
        if (item.meta.length) {
          metaExists = true;
        }
      });
      return metaExists;
    },
    showPaymentDetails(payment) {
      this.viewPaymentDetails = true;
      this.selectedPayment = payment;
    },
    openAddTag() {
      this.isAddTagVisible = true;
    },
    updateObject(newobject) {
      this.order = newobject;
    },
    orderDeleted() {
      this.$router.push({ name: "order.index" });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .collaps-wrapper h5{
  font-size: 13px;
}
.inline {
  display: inline-block;
}
.text-tight {
  line-height: 0.8rem;
}
.payment {
  width: 100%;
}
.payment .method {
  width: 40%;
}
.meta-column {
  float: left;
  width: 33.3%;
}
</style>
