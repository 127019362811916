<template>
  <span class="badge badge-pill" :class="getType">
    <slot />
  </span>
</template>

<script>
export default {
  props: {
    variant: {
      type: String,
      default: "active",
    },
  },
  computed: {
    getType() {
      if (this.variant === "creditcard") {
        return "badge-success";
      }
      if (this.variant === "processing" || this.variant === "new") {
        return "badge-secondary";
      }
      if (this.variant === "failed") {
        return "badge-danger";
      }
      if (this.variant === "cancelled") {
        return "badge-warning";
      }
      return "badge-success";
    },
  },
};
</script>
